import { dropdownTypes } from "./types";
import { apiCall } from "../../utils/apiCall";
import { CacheManager } from "../../utils/cache/CacheManager";
import { CacheRefs } from "../../utils/cache/CacheRefs";
import { CacheTypes } from "../../utils/cache/CacheTypes";

export const resetDropdownFilter = () => ({
  type: dropdownTypes.RESET_ALL_DROPDOWN_FILTER,
});

export const setDropdownAccountId = (data) => ({
  type: dropdownTypes.SET_DROPDOWN_ACCOUNT_ID,
  data: data,
});
export const setDropdownWorksiteId = (data) => ({
  type: dropdownTypes.SET_DROPDOWN_WORKSITE_ID,
  data: data,
});
export const setDropdownAssetId = (data) => ({
  type: dropdownTypes.SET_DROPDOWN_ASSET_ID,
  data: data,
});
export const setDropdownAssetTypeId = (data) => ({
  type: dropdownTypes.SET_DROPDOWN_ASSET_TYPE_ID,
  data: data,
});
export const setDropdownUserAccessLevel = (data) => ({
  type: dropdownTypes.SET_DROPDOWN_USER_ACCESS_LEVEL,
  data: data,
});
export const setDropdownProductType = (data) => ({
  type: dropdownTypes.SET_DROPDOWN_PRODUCT_TYPE,
  data,
});
export const setQrcodeFilterBy = (data) => ({
  type: dropdownTypes.SET_QRCODE_FILTER_BY,
  data: data,
});

export const getDropdownAccountListLoading = () => ({
  type: dropdownTypes.GET_DROPDOWN_ACCOUNT_LIST_LOADING,
});

export const getDropdownAccountListError = () => ({
  type: dropdownTypes.GET_DROPDOWN_ACCOUNT_LIST_ERROR,
});
export const getDropdownAccountListLoaded = (data) => ({
  type: dropdownTypes.GET_DROPDOWN_ACCOUNT_LIST_LOADED,
});

// ACCOUNTS
export const getDropdownAccountList = () => {
  return async (dispatch, getState) => {
    // const state = getState();
    // const { accountList, accountListLoading } = state.dropdownFilters;
    // if (accountList.length > 0 || accountListLoading) {
    //   return;
    // }
    //Below code to stop any other user accessing accounts list other than Super admins
    const state = getState();
    if (!state.user.isSuperAdmin) {
      return;
    }

    const cache = CacheManager.getInstance();
    if (cache) {
      const cacheResult1 = await cache.get(CacheRefs.accountsDDList(), CacheTypes.FBC);
      const cacheResult2 = await cache.get(CacheRefs.accountById(), CacheTypes.FBC);
      if (cacheResult1 && cacheResult2) {
        dispatch({
          type: dropdownTypes.GET_DROPDOWN_ACCOUNT_LIST,
          data: cacheResult1,
        });
        dispatch({
          type: dropdownTypes.GET_DROPDOWN_ACCOUNT_LIST_BY_ID,
          data: cacheResult2,
        });
        dispatch(getDropdownAccountListLoaded());
        return;
      }
    }
    dispatch(getDropdownAccountListLoading());
    apiCall(`/dropdown/accounts`)
      .then((resp) => {
        const accountList = resp?.data?.data?.data?.map((d) => {
          return {
            _id: d._id,
            companyName: d.companyName || d.email || "NA",
            // email: d.email,
          };
        });
        const accountListById = {};
        resp?.data?.data?.data?.map((d) => {
          accountListById[d._id] = {
            _id: d._id,
            companyName: d.companyName || d.email || "NA",
          };
          return true;
        });

        dispatch({
          type: dropdownTypes.GET_DROPDOWN_ACCOUNT_LIST,
          data: accountList,
        });
        dispatch({
          type: dropdownTypes.GET_DROPDOWN_ACCOUNT_LIST_BY_ID,
          data: accountListById,
        });
        dispatch(getDropdownAccountListLoaded());
        if (cache) {
          if (accountList?.length > 0) {
            cache.set(CacheRefs.accountsDDList(), accountList, null, CacheTypes.FBC);
          }
          if (accountListById?.length > 0) {
            cache.set(CacheRefs.accountById(), accountListById, null, CacheTypes.FBC);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(getDropdownAccountListLoaded());
      });
  };
};

// TODO: Optimize this when time permits
export const getDropdownWorksiteList = (isAssetPermission, skipCache) => {
  return async (dispatch, getState) => {
    const state = getState();

    // let accountId = state && state.user && state.user.loginDetails && state.user.loginDetails.accountId;
    // if (state.user.isSuperAdmin) {
    //   accountId = state.dropdownFilters.accountId;
    // }
    const accountId = state?.user?.isSuperAdmin
      ? isAssetPermission
        ? state?.dropdownFilters?.settings?.accountId
        : state?.dropdownFilters?.accountId
      : state?.user?.loginDetails?.accountId;

    if (!accountId) {
      return;
    }

    let url = `/dropdown/sites?accid=${accountId}`;
    const cache = CacheManager.getInstance();

    if (cache && !skipCache) {
      const cacheResult1 = await cache.get(url, CacheTypes.FBC);
      if (cacheResult1) {
        dispatch({
          type: dropdownTypes.GET_DROPDOWN_WORKSITE_LIST_FOR_SETTINGS_SUCCESS,
          data: cacheResult1,
        });
        return dispatch({
          type: dropdownTypes.GET_DROPDOWN_WORKSITE_LIST,
          data: cacheResult1,
        });
      }
    }

    apiCall(url)
      .then((resp) => {
        let authorizedSites = resp?.data?.data?.data;
        if (cache) {
          if (authorizedSites) {
            cache.set(url, authorizedSites, null, CacheTypes.FBC);
          }
        }

        dispatch({
          type: dropdownTypes.GET_DROPDOWN_WORKSITE_LIST_FOR_SETTINGS_SUCCESS,
          data: authorizedSites,
        });

        return dispatch({
          type: dropdownTypes.GET_DROPDOWN_WORKSITE_LIST,
          data: authorizedSites,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const getDropdownAssetsListForLocalFilters = (worksiteId, assetTypeId) => {
  return async (dispatch, getState) => {
    const state = getState();
    const userState = state?.user;
    const accountId = userState?.loginDetails?.accountId;
    const dropdownAccountId = state?.dropdownFilters?.accountId;
    const isSuperAdmin = userState?.isSuperAdmin;
    const isSiteAdmin = userState?.isSiteAdmin;

    const derivedWorksiteId =
      worksiteId ||
      (isSiteAdmin
        ? userState?.loginDetails?.userData?.authorizedSites?.[0]
        : state?.dropdownFilters?.worksiteId);

    const derivedAssetTypeId = assetTypeId || state?.dropdownFilters?.assetTypeId;

    // asset/filter/assetList/:accountId/:siteId/:assetTypeId/:pageNo/:pageSize/:searchText
    /* const url = `/asset/filter/assetList/${isSuperAdmin ? dropdownAccountId : accountId}/${
      derivedWorksiteId || "all"
    }/${derivedAssetTypeId || "all"}/all/all/all`; */

    const url = `/dropdown/assets?accid=${isSuperAdmin ? dropdownAccountId : accountId}&siteid=${
      derivedWorksiteId || "all"
    }&astpid=${derivedAssetTypeId || "all"}`;
    apiCall(url)
      .then((resp) => {
        return dispatch({
          type: dropdownTypes.GET_DROPDOWN_ASSET_LIST,
          data: resp?.data?.data?.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

// ASSET
export const getDropdownAssetList = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const accountId = state?.user?.loginDetails?.accountId;
    const dropdownAccountId = state?.dropdownFilters?.accountId;
    let worksiteId = state?.dropdownFilters?.worksiteId;
    const assetTypeId = state?.dropdownFilters?.assetTypeId;
    const isSuperAdmin = state && state.user && state.user.isSuperAdmin;

    let isSiteAdmin = state?.user?.isSiteAdmin;
    if (isSiteAdmin) {
      worksiteId = state?.user?.loginDetails?.userData?.authorizedSites?.[0];
    }

    // asset/filter/assetList/:accountId/:assetTypeId/:siteId/:pageNo/:pageSize/:searchText

    // const url = `/asset/filter/assetList/${isSuperAdmin ? dropdownAccountId : accountId}/${
    //   assetTypeId ? assetTypeId : "all"
    //}/${worksiteId ? worksiteId : "all"}/all/all/all`;

    const url = `/dropdown/assets?accid=${isSuperAdmin ? dropdownAccountId : accountId}&siteid=${
      worksiteId ? worksiteId : "all"
    }&astpid=${assetTypeId ? assetTypeId : "all"}`;

    const cache = CacheManager.getInstance();
    if (cache) {
      const cacheResult1 = await cache.get(url, CacheTypes.FBC);
      if (cacheResult1) {
        return dispatch({
          type: dropdownTypes.GET_DROPDOWN_ASSET_LIST,
          data: cacheResult1,
        });
      }
    }

    apiCall(url)
      .then((resp) => {
        if (cache) {
          if (resp?.data?.data?.data?.length > 0) {
            cache.set(url, resp?.data?.data?.data, null, CacheTypes.FBC);
          }
        }
        return dispatch({
          type: dropdownTypes.GET_DROPDOWN_ASSET_LIST,
          data: resp?.data?.data?.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

// ASSET TYPE
export const getDropdownAssetTypeList = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const isSuperAdmin = state?.user?.isSuperAdmin;
    const dropdownAccountId = isSuperAdmin
      ? state?.dropdownFilters?.accountId
      : state?.user?.loginDetails?.accountId;

    if (!dropdownAccountId || dropdownAccountId === "all") {
      return;
    }
    const url = `/dropdown/assetTypes?accid=${dropdownAccountId}`;
    const cache = CacheManager.getInstance();
    if (cache) {
      const cacheResult1 = await cache.get(url);
      if (cacheResult1) {
        return dispatch({
          type: dropdownTypes.GET_DROPDOWN_ASSET_TYPE_LIST,
          data: cacheResult1,
        });
      }
    }
    apiCall(url)
      .then((resp) => {
        if (cache) {
          if (resp?.data?.data?.data) {
            cache.set(url, resp.data.data.data);
          }
        }

        return dispatch({
          type: dropdownTypes.GET_DROPDOWN_ASSET_TYPE_LIST,
          data: resp?.data?.data?.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

// ASSET TYPE BY WORKSITE ID
export const getDropdownAssetTypeByWorksite = (siteId) => {
  return async (dispatch, getState) => {
    const state = getState();
    const isSuperAdmin = state?.user?.isSuperAdmin;
    const dropdownAccountId = isSuperAdmin
      ? state?.dropdownFilters?.accountId
      : state?.user?.loginDetails?.accountId;
    const dropdownSiteId = siteId || state?.dropdownFilters?.worksiteId;

    if (dropdownSiteId === true || !dropdownSiteId || !dropdownAccountId) {
      return;
    }

    const url = `/assetType/filter/assetTypeListBysiteID/${dropdownAccountId}/${dropdownSiteId}`;
    const cache = CacheManager.getInstance();
    if (cache) {
      const cacheResult1 = await cache.get(url);
      if (cacheResult1) {
        return dispatch({
          type: dropdownTypes.GET_DROPDOWN_ASSET_TYPE_LIST,
          data: cacheResult1,
        });
      }
    }

    apiCall(url)
      .then((resp) => {
        if (cache) {
          if (!resp?.data?.data) {
            cache.set(url, resp.data.data);
          }
        }
        return dispatch({
          type: dropdownTypes.GET_DROPDOWN_ASSET_TYPE_LIST,
          data: resp.data.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

// DASHBOARD USER
export const getDropdownUserList = (worksiteId) => {
  return async (dispatch, getState) => {
    const state = getState();
    const accountId = state?.user?.loginDetails?.accountId;
    const dropdownAccountId = state?.dropdownFilters?.accountId;
    const isSuperAdmin = state && state.user && state.user.isSuperAdmin;

    const url = `/user/list/dashboard_users/${
      isSuperAdmin ? dropdownAccountId : accountId
    }/${worksiteId || "all"}/all/all/all`;

    const cache = CacheManager.getInstance();
    if (cache) {
      const cacheResult1 = await cache.get(url);
      if (cacheResult1) {
        return dispatch({
          type: dropdownTypes.GET_DROPDOWN_USER_LIST,
          data: cacheResult1,
        });
      }
    }

    apiCall(url)
      .then((resp) => {
        if (cache) {
          if (resp?.data?.data) {
            cache.set(url, resp.data.data);
          }
        }

        return dispatch({
          type: dropdownTypes.GET_DROPDOWN_USER_LIST,
          data: resp.data.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

// PRODUCT LIST
export const getDropdownProductList = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const accountId = state.user.isSuperAdmin
      ? state?.dropdownFilters?.accountId
      : state?.user?.loginDetails?.accountId;

    if (!accountId) {
      return;
    }
    const url = `/asset/getproducttypes`;
    const cache = CacheManager.getInstance();
    if (cache) {
      const cacheResult1 = await cache.get(url);
      if (cacheResult1) {
        return dispatch({
          type: dropdownTypes.GET_DROPDOWN_PRODUCT_LIST,
          data: cacheResult1,
        });
      }
    }
    apiCall(url)
      .then((resp) => {
        const respData = resp.data.data;
        const formattedData = respData.map((d) => {
          return { _id: d, name: d };
        });
        if (cache) {
          if (formattedData) {
            cache.set(url, formattedData);
          }
        }
        return dispatch({
          type: dropdownTypes.GET_DROPDOWN_PRODUCT_LIST,
          data: formattedData,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const getDropdownUserListByUserType = (worksiteId, userType) => {
  return async (dispatch, getState) => {
    if (worksiteId === true || !worksiteId || userType === true || !userType) {
      return;
    }
    const state = getState();
    const accountId = state?.user?.isSuperAdmin
      ? state?.dropdownFilters?.settings?.accountId
      : state?.user?.loginDetails?.accountId;

    if (!accountId) {
      return;
    }
    const url = `/user/list/${userType}/${accountId || "all"}/${worksiteId}/all/all/all`;

    const cache = CacheManager.getInstance();
    if (cache) {
      const cacheResult1 = await cache.get(url);
      if (cacheResult1) {
        return dispatch({
          type: dropdownTypes.GET_DROPDOWN_USERS_BY_USER_TYPE_SUCCESS,
          data: cacheResult1,
        });
      }
    }
    apiCall(url)
      .then((response) => {
        const formattedData = response.data.data.map((user) => {
          return { _id: user._id, name: user.fullName };
        });
        if (cache) {
          if (formattedData) {
            cache.set(url, formattedData);
          }
        }
        return dispatch({
          type: dropdownTypes.GET_DROPDOWN_USERS_BY_USER_TYPE_SUCCESS,
          data: formattedData,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const setDropdownUserTypeId = (userTypeId) => {
  return async (dispatch) => {
    return dispatch({
      type: dropdownTypes.SET_DROPDOWN_USER_TYPE_ID,
      data: userTypeId,
    });
  };
};

export const setDropdownUserId = (userId) => {
  return async (dispatch) => {
    return dispatch({
      type: dropdownTypes.SET_DROPDOWN_USER_ID,
      data: userId,
    });
  };
};
